import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    console.log("drag")
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      // handle: '.handle', // handle's class
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let data = new FormData()
    data.append("position", event.newIndex + 1)
    console.log(event.newIndex)

    patch(this.data.get("url").replace(":id", id), {body: data})

    // Rails.ajax({
    //   url: this.data.get("url").replace(":id", id),
    //   type: 'PATCH',
    //   data: data
    // })
  }
}