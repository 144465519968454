import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    percent: Number
  }

  connect() {
    this.setGaugeValue(this.percentValue);
  }

  percentValueChanged() {
    this.setGaugeValue(this.percentValue);
  }

  setGaugeValue(value) {
    const gauge = this.element;

    if (value < 0 || value > 100) {
      return;
    }

    // calculation for the needle
    // total = 35
    // -17.5deg; = 0
    // 0 = 50
    // 17.5 = 100

    const needle_degrees = (value / 100) * 35 - 17.5;
    
    // calculation for the fill
    // total = 31
    // 34.5 = 0
    // 65.5 = 100
    // 50 = 50
    const min_value = 34.5;
    const max_value = 65.5;
    const slope = (max_value - min_value) / 100;
    const fill_percentage = min_value + slope * value;

    var fill_color = "";
    if (value < 30) {
      fill_color = "#ef4444";
    } else if (value < 50) {
      fill_color = "#fb923c";
    } else if (value < 75) {
      fill_color = "#4ade80";
    } else {
      fill_color = "#4ade80";
    }

    gauge.querySelector(".gauge-needle").style.setProperty('--gauge-needle-rotate', `${needle_degrees}deg`);
    gauge.querySelector(".gauge-fill-wrapper").style.setProperty('--gauge-fill', `${fill_percentage}%`);
    this.element.style.setProperty('--gauge-fill-color', `${fill_color}`);
  }
}