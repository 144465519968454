import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'doors', 'passes', 'attempts', 'hours', 'shifts', 'suggestedVolunteers' ]

  connect() {
    this.updateVolunteers()
  }

  updateVolunteers () {
    const suggestedVolunteers = ((this.doorsValue() * this.passesValue()) / this.attemptsValue()) / this.hoursValue()
    this.suggestedVolunteersTarget.innerHTML = Math.round(suggestedVolunteers)
    // console.log(suggestedVolunteers)
  }

  doorsValue () {
    // console.log(parseFloat(this.doorsTarget.value))
    return parseFloat(this.doorsTarget.value)
  }

  passesValue () {
    // console.log(parseFloat(this.passesTarget.value))
    return parseFloat(this.passesTarget.value)
  }

  attemptsValue () {
    // console.log(parseFloat(this.attemptsTarget.value))
    return parseFloat(this.attemptsTarget.value)
  }

  hoursValue () {
    // console.log(parseFloat(this.hoursTarget.value))
    return parseFloat(this.hoursTarget.value)
  }

  // shiftsValue () {
  //   // console.log(parseFloat(this.hoursTarget.value))
  //   return parseFloat(this.shiftsTarget.value)
  // }
}