import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Chart controller connected");
    window.addEventListener("turbo:morph", this.refresh);
  }

  refresh = () => {
    var chart = Chartkick.charts["vote_chart"];
    chart.updateData("localhost:3000/results/bar_chart");
  }
}