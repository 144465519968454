import { Controller } from "@hotwired/stimulus"
import Konami from 'konami'

export default class extends Controller {
  static targets = [ 'html' ]

  connect() {
    const easterEgg = new Konami(() => {
      this.toggle()
    })
  }

  toggle () {
    if (this.htmlTarget.classList.contains("paper")) {
      this.htmlTarget.classList.toggle("paper")
      console.log("Wallchart Mode Deactivated.")
    }
    else {
      this.htmlTarget.classList.toggle("paper")
      console.log("Wallchart Mode Activated!")

      setTimeout(function() {
        alert("Wallchart Mode Activated!")
      }, 100);
    }
  }
}