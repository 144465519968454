// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import ahoy from "ahoy.js"
window.ahoy = ahoy;

import Alpine from 'alpinejs' 
window.Alpine = Alpine
Alpine.start()

import "chartkick/chart.js"

import { Chart } from 'chart.js';
// Make annotations for charts
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(annotationPlugin);
Chart.register(ChartDataLabels);