import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  submitForm(){
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.element.requestSubmit()
    }, 100)
  }
}