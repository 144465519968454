import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['table']
  static values = { secondaryColumn: String }

  // only connect once
  initialize() {
    this.sortChildren(false);
  }

  disconnect() {
    document.removeEventListener("turbo:visit", this.boundSort);
  }

  // fires when the table contents are updated
  connect() {
    this.boundSort = this.sortChildren.bind(this);

    // page visit
    document.addEventListener("turbo:visit", this.boundSort);
  }

  sortChildren(event) {
    const sort_url = event ? event.detail.url : false;
    const columnName = this.getSortColumn(sort_url) || 'number';
    const sortDirection = this.getSortDirection(sort_url) || 'asc';

    const tbody = this.tableTarget.querySelector("tbody");
    const rows = Array.from(tbody.querySelectorAll("tr"));
    const headers = Array.from(this.tableTarget.querySelectorAll('thead th'));
    
    const columnNameLookup = headers.map(header => header.querySelector('a') ? header.querySelector('a').dataset.sortColumn || header.querySelector('a').innerText.trim().toLowerCase() : "");
    const columnIndex = columnNameLookup.indexOf(columnName.toLowerCase());
    const secondarySortColumnIndex = this.secondaryColumnValue ? columnNameLookup.indexOf(this.secondaryColumnValue) : columnNameLookup.indexOf('number');

    const sortedRows = [...rows].sort((rowA, rowB) => {
      const primarySortType = this.getColumnDataType(rowA, columnIndex);
      const secondarySortType = this.getColumnDataType(rowA, secondarySortColumnIndex);

      const cellA = this.getCellData(rowA, columnIndex, primarySortType);
      const cellB = this.getCellData(rowB, columnIndex, primarySortType);

      const secondarySortCellA = this.getCellData(rowA, secondarySortColumnIndex, secondarySortType);
      const secondarySortCellB = this.getCellData(rowB, secondarySortColumnIndex, secondarySortType);

      const primarySort = this.getSortAlgorithm(cellA, cellB, primarySortType, sortDirection);
      const secondarySort = this.getSortAlgorithm(secondarySortCellA, secondarySortCellB, secondarySortType, 'asc');

      return primarySort || secondarySort;
    });

    this.writeRowsToTable(sortedRows);

    // Update the header's CSS
    const currentSortLink = headers[columnIndex].querySelector('a');
    currentSortLink.classList.remove("current-sort", "asc", "desc");
    currentSortLink.classList.add("current-sort", sortDirection);
  }

  getSortAlgorithm(a, b, sortType, sortDirection) {
    // swap cells for descending sort
    if(sortDirection === "desc") [a, b] = [b, a];

    if (sortType === 'string') {
      return a.localeCompare(b);
    }
    // Empty string is terrible apprently
    if (sortType === 'text') {
      return  a ? b ? a.localeCompare(b) : -1 : 1;
    }
    // sort for sortType = [number, timestamp, checkbox]
    return a - b;
  }

  getCellData(row, columnIndex, sortType) {
    if (sortType === 'checkbox') {
      const checkbox = row.cells[columnIndex].querySelector('input[type="checkbox"]'); 
      return !!checkbox.checked;
    }
    if (sortType === 'timestamp') {
      return parseInt(row.cells[columnIndex].dataset.timestamp) || 0;
    }
    if (sortType === 'text') {
      return row.cells[columnIndex].querySelector('input[type="text"]').value;
    }

    const headers = Array.from(this.tableTarget.querySelectorAll('thead th'));
    const sortSelector = headers[columnIndex].querySelector('a').dataset.sortSelector;
    const cellText = !!sortSelector ? row.cells[columnIndex].querySelector(sortSelector).innerText.trim() : row.cells[columnIndex].innerText.trim();
    
    if (sortType === 'number') {
      return parseInt(cellText) || 0;
    }  
    if (sortType === 'string') {
      return cellText;
    }

    return '';
  }

  writeRowsToTable(rows) {
    // Create a new DocumentFragment
    const fragment = document.createDocumentFragment();

    // Append sorted rows to the DocumentFragment
    rows.forEach(row => fragment.appendChild(row));

    // Select the tr elements directly from the tbody
    const tbody = this.tableTarget.querySelector("tbody");
    const trs = Array.from(tbody.querySelectorAll("tr"));

    // Remove the tr elements
    trs.forEach(tr => tr.remove());

    // Append the DocumentFragment to tbody, causing a single reflow
    tbody.appendChild(fragment);
  }

  getSortDirection(url) {
    url = new URL(url || document.URL);
    return url.searchParams.get('direction');
  }

  getSortColumn(url) {
    url = new URL(url || document.URL);
    return url.searchParams.get('sort');
  }

  getColumnDataType(row, columnIndex) {
    const cell = row.cells[columnIndex];
    if (cell.querySelector('input[type="checkbox"]')) return 'checkbox';
    if (cell.querySelector('input[type="text"]')) return 'text';
    if (cell.hasAttribute('data-timestamp')) return 'timestamp';
    if (!isNaN(parseInt(cell.innerText.trim()))) return 'number';
    if (typeof cell.innerText.trim() === 'string') return 'string';
    return 'unknown';
  }
}